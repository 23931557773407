.exam-summary {
  margin: 0.533333rem 0 1.066667rem;
}

.summary-title {
  font-size: 0.426667rem;
  font-weight: 600;
  color: rgba(51,51,51,0.85);
  line-height: 0.586667rem;
  margin-bottom: 0.213333rem;
}

.colorBlindess {
  display: flex;
  justify-content: space-between;
  margin: 0.533333rem 0 0.266667rem;
}

.colorBlindess span {
  color: #333;
  font-size: 0.373333rem;
  font-weight: bold;
}

.line2 {
  font-size: 0.32rem;
  color: #999999;
  margin-bottom: 1.066667rem;
}

.interview_time {
  width: 100%;
  margin: 0.266667rem 0;
  padding: 0.266667rem;
  background: #f3f9ff;
  border-radius: 0.213333rem;
}

.interview_time .use_time {
  font-size: 0.373333rem;
  color: #333333;
  display: flex;
  align-items: center;
}

.interview_time .use_time .trans_icon {
  color: #1890ff;
}

.interview_time .use_time .trans_icon .active_icon {
  transform: rotate(90deg);
}

.interview_time .use_time .clockimg {
  width: 0.426667rem;
  height: 0.426667rem;
  margin-right: 0.053333rem;
  position: relative;
  top: 0.08rem;
}

.interview_time .time {
  border-radius: 0.133333rem;
  font-size: 0.32rem;
  color: #6d7278;
}

.interview_time .time .begin_time span {
  font-weight: 400;
}

.interview_time .time .begin_time+.begin_time {
  margin-top: 0.133333rem;
}

.question-detail-wrapper {
  margin: 0.266667rem auto 0 auto;
}

.question-detail-wrapper .gray {
  color: #666666;
}

.question-detail-wrapper .green {
  color: #6ed401;
}

.question-detail-wrapper .red {
  color: #ff0000;
}

.question-detail-wrapper .yellow {
  color: #f4d75e;
}

.question-detail-wrapper .line-20 {
  line-height: 0.533333rem;
}

.question-detail-wrapper pre {
  word-break: break-word;
  white-space: pre-wrap;
}

.question-detail-wrapper pre img {
  max-width: 100%;
  object-fit: contain;
}

.question-detail-wrapper /deep/ .van-tabs .van-tabs__line {
  background: #1890ff;
}

.question-detail-wrapper /deep/ .van-tabs .van-tab--active {
  font-weight: 550;
}

.question-detail-wrapper /deep/ .van-tabs .van-tabs__content {
  padding-bottom: 1.066667rem;
}

.question-detail-wrapper .comlun {
  flex-direction: column;
  justify-content: space-between;
}

.question-detail-wrapper /deep/.van-panel .van-panel__header {
  padding-top: 0.373333rem;
  padding-bottom: 0.373333rem;
}

.question-detail-wrapper /deep/.van-panel .van-panel__header .van-cell__title {
  font-size: 0.4rem;
  color: #333333;
  font-weight: 550;
}

.question-detail-wrapper /deep/.van-panel .van-panel__content {
  padding: 0 0.533333rem;
}

.question-detail-wrapper /deep/ .van-panel::after {
  content: none;
}

.question-detail-wrapper .question {
  margin-top: 0.266667rem;
}

.question-detail-wrapper .question /deep/ img {
  max-width: 100%;
}

.question-detail-wrapper .question .video-wrapper video {
  width: 100%;
}

.question-detail-wrapper .question .question-type {
  font-weight: bold;
  margin: 0.426667rem 0 0.266667rem;
}

.question-detail-wrapper .question .question-type .isExpand {
  margin-left: 0.266667rem;
  color: #1890FF;
  font-weight: normal;
}

.question-detail-wrapper .question .question-type .top__title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 0.48rem;
  color: #333333;
  text-align: left;
  font-style: normal;
  padding: 0 0.213333rem;
  position: relative;
}

.question-detail-wrapper .question .question-type .top__title::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.213333rem;
  background: #1890FF;
  opacity: 0.15;
  position: absolute;
  bottom: 0;
  left: 0;
}

.question-detail-wrapper .question .question-item {
  display: flex;
  justify-content: space-between;
  line-height: 0.373333rem;
  margin: 0.266667rem 0;
}

.question-detail-wrapper .question .question-item span {
  color: #333;
  font-size: 0.373333rem;
  font-weight: bold;
}