.exam-summary {
  margin: 0.533333rem 0 1.066667rem;
}

.summary-title {
  font-size: 0.426667rem;
  font-weight: 600;
  color: rgba(51,51,51,0.85);
  line-height: 0.586667rem;
  margin-bottom: 0.213333rem;
}

.line2 {
  font-size: 0.32rem;
  color: #999999;
  margin-bottom: 1.066667rem;
}

.interview_time {
  width: 100%;
  margin: 0.266667rem 0;
  padding: 0.266667rem;
  background: #f3f9ff;
  border-radius: 0.213333rem;
}

.interview_time .use_time {
  font-size: 0.373333rem;
  color: #333333;
  display: flex;
  align-items: center;
}

.interview_time .use_time .trans_icon {
  color: #1890ff;
}

.interview_time .use_time .trans_icon .active_icon {
  transform: rotate(90deg);
}

.interview_time .use_time .clockimg {
  width: 0.426667rem;
  height: 0.426667rem;
  margin-right: 0.053333rem;
  position: relative;
  top: 0.08rem;
}

.interview_time .time {
  border-radius: 0.133333rem;
  font-size: 0.32rem;
  color: #6d7278;
}

.interview_time .time .begin_time span {
  font-weight: 400;
}

.interview_time .time .begin_time+.begin_time {
  margin-top: 0.133333rem;
}

.indicator-wrappper {
  position: relative;
}

.indicator-wrappper .get-score {
  font-size: 0.426667rem;
  font-weight: 600;
  color: #333333;
  margin-bottom: 0.4rem;
}

.indicator-wrappper .get-score span {
  color: #1890ff;
}

.note-wrapper {
  margin: 0.8rem auto;
  padding: 0.266667rem;
  background-color: #f0f3f7;
  border-radius: 0.266667rem;
}

.note-wrapper .note-title {
  font-size: 0.373333rem;
  font-weight: 600;
  color: #333333;
}

.note-wrapper .note-content {
  margin-top: 0.266667rem;
  color: #333333;
  font-size: 0.373333rem;
  line-height: 0.533333rem;
}

.answer-situation {
  margin-top: 0.533333rem;
  font-size: 0.426667rem;
  font-weight: 600;
  color: rgba(51,51,51,0.85);
  line-height: 0.586667rem;
}

.answer-situation-wrapper {
  height: 1.333333rem;
  margin: 0.426667rem auto;
  background: #ffffff;
  border-radius: 0.266667rem;
  border: 0.026667rem dashed #d6dde8;
  display: flex;
  align-items: center;
  padding: 0 0.533333rem 0 0.213333rem;
  font-size: 0.373333rem;
  justify-content: space-between;
}

.answer-situation-wrapper .answer-numer {
  display: flex;
  align-items: center;
}

.answer-situation-wrapper .answer-numer .right .number {
  font-size: 0.48rem;
  font-weight: 600;
  color: #1890ff;
}

.answer-situation-wrapper .answer-numer .wrong {
  margin-left: 1.493333rem;
}

.answer-situation-wrapper .answer-numer .wrong .number {
  font-size: 0.48rem;
  color: #ff7575;
  font-weight: 600;
}

.answer-situation-wrapper .show-answer-detail {
  cursor: pointer;
  font-size: 0.373333rem;
  color: #1890ff;
}

.question-detail-wrapper {
  margin: 0.266667rem auto 0 auto;
}

.question-detail-wrapper .gray {
  color: #666666;
}

.question-detail-wrapper .green {
  color: #6ed401;
}

.question-detail-wrapper .red {
  color: #ff0000;
}

.question-detail-wrapper .yellow {
  color: #f4d75e;
}

.question-detail-wrapper .line-20 {
  line-height: 0.533333rem;
}

.question-detail-wrapper pre {
  word-break: break-word;
  white-space: pre-wrap;
}

.question-detail-wrapper pre img {
  max-width: 100%;
  object-fit: contain;
}

.question-detail-wrapper /deep/ .van-tabs .van-tabs__line {
  background: #1890ff;
}

.question-detail-wrapper /deep/ .van-tabs .van-tab--active {
  font-weight: 550;
}

.question-detail-wrapper /deep/ .van-tabs .van-tabs__content {
  padding-bottom: 1.066667rem;
}

.question-detail-wrapper .comlun {
  flex-direction: column;
  justify-content: space-between;
}

.question-detail-wrapper /deep/ .obj-pagination {
  justify-content: center;
  position: fixed;
  bottom: 0.533333rem;
  left: 50%;
  transform: translateX(-50%);
}

.question-detail-wrapper /deep/ .obj-pagination .van-pagination__page {
  flex-grow: 1;
}

.question-detail-wrapper /deep/ .obj-pagination li:last-child {
  display: none;
}

.question-detail-wrapper /deep/ .obj-pagination li:first-child {
  display: none;
}

.question-detail-wrapper /deep/ .obj-pagination li {
  cursor: pointer;
  width: 1.066667rem;
  height: 1.066667rem;
  border-radius: 0.08rem;
  margin: 0 0.213333rem;
  border: 0.026667rem solid #ddd;
}

.question-detail-wrapper .video-title {
  padding: 0 0.533333rem;
}

.question-detail-wrapper .obj-title {
  font-size: 0.32rem;
  color: #666666;
}

.question-detail-wrapper .obj-answer {
  border: 0.026667rem dashed #dddddd;
  border-radius: 0.133333rem;
  padding: 0.32rem 0.426667rem;
  padding: 0.373333rem;
}

.question-detail-wrapper .obj-answer div:first-child {
  color: #333333;
  font-weight: 500;
}

.question-detail-wrapper .obj-answer pre {
  margin: 0 !important;
}

.question-detail-wrapper .obj-answer pre p {
  margin: 0 !important;
}

.question-detail-wrapper .sub-answer {
  border: 0.026667rem dashed #dddddd;
  border-radius: 0.133333rem;
  padding: 0.32rem 0.426667rem;
  margin-top: 0.266667rem;
}

.question-detail-wrapper .sub-answer div:first-child {
  color: #333333;
  font-weight: 500;
}

.question-detail-wrapper /deep/.van-panel .van-panel__header {
  padding-top: 0.373333rem;
  padding-bottom: 0.373333rem;
}

.question-detail-wrapper /deep/.van-panel .van-panel__header .van-cell__title {
  font-size: 0.4rem;
  color: #333333;
  font-weight: 550;
}

.question-detail-wrapper /deep/.van-panel .van-panel__content {
  padding: 0 0.533333rem;
}

.question-detail-wrapper /deep/ .van-panel::after {
  content: none;
}

.question-detail-wrapper .question {
  margin-top: 0.266667rem;
}

.question-detail-wrapper .question /deep/ img {
  max-width: 100%;
}

.question-detail-wrapper .question .video-wrapper video {
  width: 100%;
}

.question-detail-wrapper .question .question-type {
  font-weight: bold;
  margin: 0.426667rem 0 0.266667rem;
}

.question-detail-wrapper .question .question-type .isExpand {
  margin-left: 0.266667rem;
  color: #1890FF;
  font-weight: normal;
}

.question-detail-wrapper .question .question-type .top__title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 0.48rem;
  color: #333333;
  text-align: left;
  font-style: normal;
  padding: 0 0.213333rem;
  position: relative;
}

.question-detail-wrapper .question .question-type .top__title::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.213333rem;
  background: #1890FF;
  opacity: 0.15;
  position: absolute;
  bottom: 0;
  left: 0;
}

.question-detail-wrapper .question .question-item {
  display: flex;
  justify-content: space-between;
  line-height: 0.373333rem;
  margin: 0.266667rem 0;
}

.question-detail-wrapper .question .question-item span {
  color: #333;
  font-size: 0.373333rem;
  font-weight: bold;
}

.attach-list {
  margin-top: 0.426667rem;
}

.attach-list .attach-item {
  padding: 0 0.32rem;
  background: #f5f5f5;
  border-radius: 0.053333rem;
  font-size: 0.373333rem;
}

.attach-list .attach-item:hover {
  background: #e8f3ff;
}

.attach-list .attach-item+.attach-item {
  margin-top: 0.213333rem;
}

.attach-list .preview {
  padding: 0.266667rem 0;
  margin-left: 0.32rem;
  color: #1890ff;
}

.attach-list .flex {
  display: flex;
  align-items: center;
}

.attach-list .flex1 {
  flex: 1;
}

.attach-list .btw {
  justify-content: space-between;
}

.attach-list .overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.attach-list .mr5 {
  margin-right: 0.133333rem;
}